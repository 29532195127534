import React, {useCallback, useState} from 'react';
import QrReader from 'react-qr-reader';
import MainNavBar from "../../../../shared/components/NavBar";
import { useTimer } from 'react-timer-hook';
import {Button, Flex, InputItem, Progress, Toast, WhiteSpace, WingBlank} from "antd-mobile-v2";
import { createForm } from 'rc-form';
import {findUserQr, findUserQrApiV2} from "../../../../api/find";

const FindQrScreen = ((props) => {

    const useApiV2 = localStorage.getItem('useApiV2') === 'true';
    const expiryTimestamp = 0;
    const {
        seconds,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: () => console.warn('onExpire called') });

    const { getFieldProps } = props.form;

    const [formValue, setFormValue] = useState({customer: ''});

    const handleError = useCallback((err) => {
        console.error(err)
    },[]);

    const findUser = (data) => {
        useApiV2 
            ?
        findUserQrApiV2(data.customer,data)
            .then((result) => {
                const client = {
                    s_phone: result.data.phone,
                    is_chatbot: !!result.data.exists_chatbot ? 1: 0,
                    s_name1: result.data.last_name,
                    s_name2: result.data.first_name,
                    s_name3: result.data.middle_name,
                    d_birthday: "", // Др нет
                    n_balance: result.data.total_bonus,
                    s_loyalty_level: 0, // Тоже нет
                    s_cashier_info: result.data.cashier_info,
                    s_customer_token: data.token
                }
                Toast.info('Пользователь найден', 1);
                localStorage.setItem('client', JSON.stringify(client));
                window.location.href = `${process.env.PUBLIC_URL}/client`;
            })
            .catch( () => {
                Toast.info('Ошибка опознания кода', 1);
            })
            :
        findUserQr(data)
            .then(({result}) => {
                let res = result[0];
                if (res) {
                    Toast.info('Пользователь найден', 1);
                    localStorage.setItem('client', JSON.stringify(res));
                    window.location.href = `${process.env.PUBLIC_URL}/client`;
                }else {
                    Toast.info('Пользователь не найден', 1);
                    let t = new Date();
                    t.setSeconds(t.getSeconds() + 10);
                    restart(t);
                }
            })
            .catch( () => {
                Toast.info('Ошибка опознания кода', 1);
            });
    };

    const handleScan  = useCallback((data ) => {
        if (data) {
            console.log('scan result: OK', data);
            seconds === 0 && findUser({customer: data})

        }
    },[seconds, restart]);

    const sendForm = useCallback(() => {
        findUser(formValue)
    },[formValue, findUser]);

    return (
        <>
            <MainNavBar text="По QR коду"/>

            <div className="flex-container auth">
                <div className="title-block">Отсканируйте QR код</div>
                <WhiteSpace size="xl" />
                <QrReader
                    delay={300}
                    onError={(e) => handleError(e)}
                    onScan={e => handleScan(e)}
                    style={{ width: '100%' }}
                />
            </div>

            <WingBlank>
                <Flex justify="center" wrap="wrap">
                    <Flex.Item>
                        <InputItem
                            {...getFieldProps('customer', {
                                onChange: e => setFormValue({customer: e})
                            })}
                            clear
                            value={formValue.customer}
                            maxLength={6}
                            type="number"
                            placeholder="Или введите короткий код"
                        />
                    </Flex.Item>
                </Flex>
                <WhiteSpace size="xl" />
                <Flex justify="center" wrap="wrap">
                    <Flex.Item>
                        <Button
                            disabled={formValue.customer <= 0}
                            type="primary"
                            onClick={sendForm}
                        >
                            Войти
                        </Button>
                    </Flex.Item>
                </Flex>
            </WingBlank>

            <Progress barStyle={{border: '2px solid #FF7E42'}} position="fixed" percent={10 * seconds} />
        </>
    )
});

export default createForm()(FindQrScreen)