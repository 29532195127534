import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {
  Modal,
  Button,
  List,
  SwipeAction,
  Icon,
  WhiteSpace,
  WingBlank,
  Flex,
  InputItem,
  Toast,
  Accordion
} from "antd-mobile-v2";

import {
  Stepper
} from "antd-mobile";

import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import _ from 'lodash';
import QueueAnim from 'rc-queue-anim';
import { createForm } from 'rc-form';
import moment from 'moment';
import {addBonus, createCheck, createCheck15, createCheckV2, subBonus, writeCheck, writeCheckV2} from "../../../../../../api/check";
import {checkCode, sendCode as sendVerifyCode} from "../../../../../../api/find";
import RICIBs from "react-individual-character-input-boxes";

const Item = List.Item;
const Brief = Item.Brief;

const objectWithoutKey = (object, key) => {
  const {[key]: deletedKey, ...otherKeys} = object;
  return otherKeys;
};

const ClientCheckScreen = ((props) => {

  const defaultCalcCheck = {total:{amount: 0, discount: 0, bonus: 0}}
  const {goods, setGoods} = props;
  const client = JSON.parse(localStorage.getItem('client'));
  const needVerify = localStorage.getItem('checkCode') === '1';
  const defaultGoods = localStorage.getItem('defaultGoods');
  const processingType = localStorage.getItem('processingType');
  const sellerToken = localStorage.getItem('token');
  const useApiV2 = localStorage.getItem('useApiV2') === 'true';

  const defaultCheck = useApiV2 ? 
  {
    items:
      Object.values(goods).filter(element => element !== null).map(item => {
        return {
          code: item.s_identity,
          qty: item.count,
          mrc: item.mrc,
          price: item.price
        }
      }),
    customer_token: JSON.parse(localStorage.getItem('client')).s_customer_token,
    // coupon: "",
    // gift_code: "",
    order_type: "cashbox",
    type_of_order: "restaurant",
    certificate: [
      // {
      //   code: "",
      //   pin: "",
      //   summ: ""
      // }
    ],
  }
  : {
    body:
      Object.values(goods).filter(element => element !== null).map(item => {
        return {
          goods: item.s_identity,
          quantity: item.count,
          mrc: item.mrc,
          amount: item.price * item.count,
          bonus:"",
          action:"0",
          paybonus:"0",
          paybonus_fact: "0",
          nogoods:"0",
          discount:"0"
        }
      }),
    head: {
      check:0,
      customer: JSON.parse(localStorage.getItem('client')).s_token,
      kkm:"",
      pin:"",
      point: localStorage.getItem('point'),
      time: moment().format("DD.MM.YYYY HH:mm:ss"),
      lscheck:"0"
    },
    total:{
      amount: 0,
      discount:"0.00",
      action:"0",
      paybonus:"0",
      paybonus_fact: "0",
      bonus:"0",
      paycash:"0",
      paynocash:"0"
    }
  }

  const clientBalance = Number(JSON.parse(localStorage.getItem('client')).n_balance);

  const [formValue, setFormValue] = useState({pin: ''});
  const [defaultAmount, setDefaultAmount] = useState(0);
  const [maxBonusLimit, setMaxBonusLimit] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalCheck, setShowModalCheck] = useState(false);
  const [showModalPay, setShowModalPay] = useState(false);
  const [showModalCoupon, setShowModalCoupon] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [showModalCertificate, setShowModalCertificate] = useState(false);
  const [showModalPromocode, setShowModalPromocode] = useState(false);
  const [promocode, setPromocode] = useState("");
  const [certificate, setCertificate] = useState("");
  const [certificatePin, setCertificatePin] = useState("");
  const [hasError, setHasError] = useState(false);
  const [activeElem, setActiveElem] = useState({});
  const [check, setCheck] = useState(defaultCheck);
  const [calculateCheck, setCalculateCheck] = useState(defaultCalcCheck);
  const [maxBonusCanPay, setMaxBonusCanPay] = useState(0);

  const { getFieldProps, getFieldError } = props.form;

  const changeGoodsCount = useCallback((idnum, count) => {
    setGoods({...goods, [idnum]: {...goods[idnum], count: count}});
  },[goods]);

  const removeGoods = useCallback((idnum) => {
    setGoods(objectWithoutKey(goods, idnum))
  },[goods]);

  const changePrice = useCallback((e) => {
    setActiveElem({
      idnum: e.idnum,
      price: e.price.toString(),
      mrc: e.mrc.toString()
    });
    setShowModal(true);
  },[]);

  const confirmNewPrices = useCallback(() => {
    setShowModal(false);
    setGoods({
      ...goods,
      [activeElem.idnum]: {
        ...goods[activeElem.idnum],
        price: Number(activeElem.price),
        mrc: Number(activeElem.mrc)
      }
    });
  },[goods, activeElem]);

  const backToMain = useCallback(() => {
    const {history} = props;
    history.push({
      pathname: `${process.env.PUBLIC_URL}/`,
      state: {goods: goods}
    });
  },[goods, props]);

  const handleOutput = useCallback((e) => {
    setFormValue({pin: e})
  },[]);

  const sendCode = useCallback((e) => {
    setShowModalPay(true)

    sendVerifyCode(client)
      .then(() => {
        Toast.info('Код успешно отправлен', 1);
        setShowModalPay(true)
      })
      .catch( () => {
        Toast.info('Ошибка отправки кода', 1);
      });
  },[props]);

  const setCheckCoupon = useCallback((e) => {
    setShowModalCoupon(false)

    setCalculateCheck({...calculateCheck, coupon: coupon});
    setCheck({...check,coupon: coupon})
  },[coupon, showModal, calculateCheck, check]);

  const setCheckPromocode = useCallback((e) => {
    setShowModalPromocode(false)

    setCalculateCheck({...calculateCheck, gift_code: promocode});
    setCheck({...check,gift_code: promocode})
  },[promocode, showModal, calculateCheck, check]);

  const setCheckCertificate = useCallback((e) => {
    setShowModalCertificate(false)

    if (certificate === "") {
      setCalculateCheck({...calculateCheck, certificate: []});
      setCheck({...check,certificate: []})
    }else{
      setCalculateCheck({...calculateCheck, certificate: [{code: certificate, pin: certificatePin, summ: null}]});
      setCheck({...check,certificate: [{code: certificate, pin: certificatePin, summ: null}]})
    }
  },[certificate, certificatePin, showModal, calculateCheck, check]);

  const payOrder = useCallback(() => {
    Toast.loading('', 30);

    //console.log(defaultGoods)
    let checkNumber = `${localStorage.getItem('sellerIdnum')}${new Date().valueOf()}`;

    setIsLoading(true);

    if (useApiV2) {
      if ((calculateCheck.total_check_amount - (calculateCheck.total_discount + calculateCheck.pay_bonus)) < 0 ) {
        Toast.info('Невозможно провести чек, итоговая сумма оплаты отрицательна', 2);
        setIsLoading(false);
        return;
      }

      calcCheck((preCheck) => {
        writeCheckV2({...preCheck, code: checkNumber, pay_bonus: preCheck.total_bonus_pay, pay_bonus_pin: formValue.pin, pay_discount: preCheck.total_discount, pay_cash: preCheck.total_check_amount - (preCheck.total_discount + preCheck.total_bonus_pay)})
          .then(() => {
            Toast.success('Оплата прошла успешно', 3);
            setTimeout(() => {
              backToMain();
            }, 10);
            console.log('Чек успешно создан:', preCheck);
          })
          .catch( () => {
            Toast.hide();
            setIsLoading(false);
            Toast.info('Ошибка совершения оплаты', 1);
            console.log('Ошибка создания чека')
          });
      })
    } else {
      let payload = {
        customer: JSON.parse(localStorage.getItem('client')).s_phone,
        pin: '',
        check: checkNumber,
        amount: calculateCheck.total.amount
      };
  
      calcCheck((preCheck) => {
        if (processingType === '1') {
          preCheck.total.paybonus = preCheck.total.paybonus_fact
          preCheck.body.map((item,key) => {
            preCheck.body[key].paybonus = preCheck.body[key].paybonus_fact
          })
        }
  
        const paybonus = preCheck.total.paybonus
  
        setShowModalCheck(false)
        subBonus({...payload, bonus: parseFloat(paybonus)})
        .then(() => {
          console.log('Бонусы списаны:', parseFloat(paybonus));
          //const addCountBonus = (parseFloat(preCheck.total.amount) - parseFloat(paybonus)) * ((100*parseFloat(preCheck.total.bonus)/parseFloat(preCheck.total.amount))/100);
          addBonus({...payload, bonus: preCheck.total.bonus})
            .then(() => {
              console.log('Бонусы начислены:',  preCheck.total.bonus);
              let myJSON = JSON.stringify({...preCheck, head: {...preCheck.head, check: checkNumber}});
  
              writeCheck({check: myJSON})
                .then(() => {
                  Toast.success('Оплата прошла успешно', 3);
                  setTimeout(() => {
                    backToMain();
                  }, 10);
                  console.log('Чек успешно создан:', preCheck);
                })
                .catch( () => {
                  Toast.hide();
                  setIsLoading(false);
                  Toast.info('Ошибка совершения оплаты', 1);
                  console.log('Ошибка создания чека')
                });
            })
            .catch( () => {
              Toast.hide();
              setIsLoading(false);
              Toast.info('Ошибка начисления бонусов', 1);
              console.log('Ошибка начисления бонусов')
            });
        })
        .catch( () => {
          Toast.hide();
          setIsLoading(false);
          Toast.info('Ошибка списания бонусов', 1);
          console.log('Ошибка списания бонусов')
        });
      })
    }

    
  },[check, processingType, calculateCheck, formValue.pin]);

  useEffect(() => {
    if (defaultAmount !== 0) {
      const timer = setTimeout(()=> {
        calcCheck(() => {})
      },1000)
      return () => clearTimeout(timer);
    }

  },[defaultAmount]);

  const validateCode = useCallback(() => {

    checkCode({...client, pin: formValue.pin})
      .then(() => {
        Toast.info('Код успешно подтвержден', 1);
        payOrder()
      })
      .catch( () => {
        Toast.info('Ошибка подтверждения кода', 1);
      });

  },[formValue, payOrder]);

  const setPayBonus = useCallback((e) => {
    //let upLimit = clientBalance >= maxBonusLimit ? maxBonusLimit : clientBalance;
    //upLimit = (calculateCheck.body && calculateCheck.empty) ? clientBalance : upLimit;
    const upLimit = maxBonusLimit
    setHasError(Number(e) > Number(upLimit));

    const paybonus = Number(e);
    if (useApiV2) {
      setCalculateCheck({...calculateCheck, pay_bonus: paybonus});
      setCheck({...check,pay_bonus:paybonus})
    } else {
      setCalculateCheck({...calculateCheck, total: {...calculateCheck.total, paybonus: paybonus}});
      setCheck({...check,total: {...check.total, paybonus:paybonus}})
    }

  },[goods, maxBonusLimit, clientBalance, calculateCheck]);

  const calcCheck = useCallback((_callback) => {

    setIsLoading(true);

    let myTimer
    let newCheck

    if (useApiV2) { //apiv2

      const defaultCheckV2 = check.items.length === 0
      ? {...check, items: [{
          code: defaultGoods,
          qty: 1,
          price: defaultAmount,
          mrc: 0
        }],
        }
      : check;

      myTimer = setTimeout(()=> {
        createCheckV2(defaultCheckV2).then(({data}) => {
          data = {...data, empty: Object.values(goods).length === 0}
          setCalculateCheck(data)
          setMaxBonusCanPay((data.customer_total_bonus <= data.total_max_bonus_can_pay) ? data.customer_total_bonus : data.total_max_bonus_can_pay)
          setMaxBonusLimit(data.total_max_bonus_can_pay);
          setIsLoading(false);
          setHasError(false)
          if (coupon !== "") {
            Toast.info(data.coupon_message, 2);
          }

          if (promocode !== "" && !data.giftCodeUsed) {
            Toast.info("Промокод не действителен", 2);
          }
          
          _callback(data)
        })
        .catch(() => {
          setIsLoading(false);
        })
      },500);
    }else {

      const defaultCheck = check.body.length === 0
      ? {...check, body: [{
          goods: defaultGoods,
          quantity: 1,
          amount: defaultAmount,
          mrc: 0,
          bonus: "",
          action: "0",
          paybonus: "0",
          paybonus_fact: "0",
          nogoods: "0",
          discount: "0",
          actions: []
        }],
        total: {
        ...check.total, amount: defaultAmount
        }}
      : check;

      if (processingType === '1') { //calc v1
        let myJSON = JSON.stringify(defaultCheck);
  
        myTimer = setTimeout(()=> {
          createCheck({check: myJSON})
            .then(({result}) => {
              newCheck =
              (Object.values(goods).length === 0)
                ?
                {
                  ...result,
                  total: {
                    ...result.total,
                    paybonus: (clientBalance <= result.total.paybonus) ? clientBalance : result.total.paybonus,
                    paybonus_fact: (clientBalance <= result.total.paybonus_fact) ? clientBalance : result.total.paybonus_fact
                  },
                  empty: true
                }
                : {...result, empty: false};
  
              setCalculateCheck(newCheck)
  
              setMaxBonusCanPay((clientBalance <= result.total.paybonus) ? clientBalance : result.total.paybonus)
              setMaxBonusLimit(result.total.paybonus);
              setIsLoading(false);
              setHasError(false)
              _callback(newCheck)
            })
            .catch(() => {
              setIsLoading(false);
            })
        },500);
      }else{ //calc v1.5
        let items = []
        defaultCheck.body.map((item,key) => {
          //console.log(item)
          items[key] = {
            "mrc": item.mrc,
            "identity": item.goods,
            "quantity": item.quantity,
            "price": item.amount / item.quantity,
            "actions": item.actions
          }
        })
  
        let myJSON = {
          "token": sellerToken,
          "point": defaultCheck.head.point,
          "time": defaultCheck.head.time,
          "customer": defaultCheck.head.customer,
          "items": items,
          "amount": defaultCheck.total.amount,
          "paybonus": defaultCheck.total.paybonus
        };
  
        myTimer = setTimeout(()=> {
          createCheck15({data: myJSON})
            .then(({result}) => {
  
              result.items.map((item,key) => {
                const actionRule = _.find(item.actions, {isUsed: true})
                const actionRuleID = actionRule ? actionRule.idRule : 0
                items[key] = {
                  "goods": item.identity,
                  "quantity": item.quantity,
                  "amount": item.price,
                  "mrc": item.mrc,
                  "bonus": item.bonus,
                  "action": actionRuleID,
                  "paybonus": item.paybonus,
                  "nogoods": !!item.isExist ? 0 : 1,
                  "discount": item.discount,
                  "actions": item.actions
                }
              })
  
              newCheck = {
                body: items,
                head: {
                  check:0,
                  customer: result.customer,
                  kkm:"",
                  pin:"",
                  point: result.point,
                  time: result.time,
                  lscheck:"0"
                },
                total:{
                  amount: result.amount,
                  discount: result.discount,
                  action: "0",
                  paybonus: result.paybonus,
                  bonus: result.bonus,
                  paycash: result.amount - (result.discount + result.paybonus),
                  paynocash: 0
                }
              }
  
              newCheck = (Object.values(goods).length === 0) ?
              {
                ...newCheck,
                total: {
                  ...newCheck.total,
                  paybonus: (clientBalance <= newCheck.total.paybonus) ? clientBalance : newCheck.total.paybonus
                },
                empty: true
              }
              : {...newCheck, empty: false};
  
              setCalculateCheck(newCheck)
  
              setMaxBonusCanPay((clientBalance <= result.paybonusMax) ? clientBalance : result.paybonusMax)
              setMaxBonusLimit(result.paybonusMax)
              setIsLoading(false);
              setHasError(false)
              _callback(newCheck)
            })
            .catch(() => {
              setIsLoading(false);
            })
        },500);
      }
    }

    return () => clearTimeout(myTimer);

  },[processingType, check, maxBonusLimit, clientBalance,defaultAmount]);

  useEffect(() => {

    calcCheck(() => {})
  
  },[clientBalance, useApiV2 ? check.items : check.body, useApiV2 ? check.coupon : check.head.coupon, useApiV2 ? check.certificate : check.head.certificate, useApiV2 ? check.gift_code : check.head.gift_code]);

  useEffect(() => {
    let formattedGoods = Object.values(goods).filter(element => element !== null);

    let sum = 0;
    formattedGoods.map(item => sum = (item.price * item.count) + sum);

    if (useApiV2) {
      setCheck({
        items:
          Object.values(goods).filter(element => element !== null).map(item => {
            return {
              code: item.s_identity,
              qty: item.count,
              mrc: item.mrc,
              price: item.price
            }
          }),
        customer_token: JSON.parse(localStorage.getItem('client')).s_customer_token,
        order_type: "cashbox",
        type_of_order: "restaurant",
        coupon: coupon,
        gift_code: promocode,
        certificate: certificate === "" ? [] : [{code: certificate, pin: certificatePin, summ: null}]
      });
    }else {
      if (calculateCheck.total.amount !== 0) {
        setCheck({
          body:
            formattedGoods.map(item => {
              return {
                goods: item.s_identity,
                quantity: item.count,
                amount: item.price * item.count,
                mrc: item.mrc,
                bonus:"",
                action:"0",
                paybonus:"0",
                nogoods:"0",
                discount:"0"
              }
            }),
          head: {
            check:0,
            customer: JSON.parse(localStorage.getItem('client')).s_token,
            kkm:"",
            pin:"",
            point: localStorage.getItem('point'),
            time: moment().format("DD.MM.YYYY HH:mm:ss"),
            lscheck:"0",
            coupon: coupon,
            gift_code: promocode,
            certificate: certificate
          },
          total:{
            amount: sum,
            discount:"0.00",
            action:"0",
            paybonus:"0",
            bonus:"0",
            paycash:"0",
            paynocash:"0"
          }
        });
      }
    }
    
  },[goods]);

  const sendReturn = useCallback((e) => {
    const {history} = props;
    history.push({
      pathname: `${process.env.PUBLIC_URL}/client/return`
    });
  }, [props]);

  const clearCheck = useCallback((e) => {
    setGoods({});
    setCalculateCheck(defaultCalcCheck);
    if (useApiV2) {
      setCheck({...defaultCheck, items: []})
    }else {
      setCheck({...defaultCheck, body: []})
    }

    //setCheck(defaultCheck)

  }, [goods, calculateCheck, check]);

  useEffect(() => {
    //console.log(calculateCheck)
    //console.log(calculateCheck.body, stateGoods)
  },[calculateCheck]);

  const onCountChange = useMemo(() => _.debounce((e, item) => {
    if (_.isString(e) && e.endsWith('.')) {
      return;
    }
    changeGoodsCount(item.idnum, e)
  }, 500), [goods])

  return (
    <>
      {Object.values(goods).filter(element => element !== null).length > 0 &&
      <>
        <WingBlank style={{textAlign: 'end'}}>
          <Button type="ghost" size="small" inline onClick={() => clearCheck()}>Очистить</Button>
        </WingBlank>

        <List>
          {Object.values(goods).filter(element => element !== null).map((item, key) => (
            <QueueAnim key={key} delay={300} className="queue-simple">
              <SwipeAction
                autoClose
                right={[
                  {
                    text: <FaRegEdit style={{fontSize: 30}}/>,
                    onPress: () => changePrice(item),
                    style: { backgroundColor: '#2F80ED', color: 'white', padding: 10 },
                  },
                  {
                    text: <FaRegTrashAlt style={{fontSize: 30}}/>,
                    onPress: () => removeGoods(item.idnum),
                    style: { backgroundColor: '#E10606', color: 'white', padding: 10 },
                  }
                ]}
              >
                <List.Item
                  key={item.count}
                  onClick={() => {}}
                  wrap={true}
                  multipleLine
                  extra={
                    <div className='wrapper-stepper-goods'>
                      <Stepper
                        disabled={isLoading}
                        className='stepper-goods'
                        min={0.01}
                        step={1}
                        digits={2}
                        showNumber
                        defaultValue={item.count}
                        onChange={e => onCountChange(e, item)}
                      /> 
                    </div>
                  }
                >
                  {item.s_name}
                  <Brief>
                    {`Цена: `}
                    {
                    useApiV2 ? 
                    _.find(calculateCheck.items, {code: item.s_identity}) && Number(_.find(calculateCheck.items, {code: item.s_identity}).calc_discount) !== 0 ?
                      <>
                        <span className='basic-price'>
                          {Number(item.price * item.count).toFixed(2)} ₽
                        </span>
                        <span className='price-with-discount'>
                        {isLoading ? <Icon
                          type='loading'/> : _.find(calculateCheck.items, {code: item.s_identity}) && Number((item.price * item.count) - _.find(calculateCheck.items, {code: item.s_identity}).calc_discount).toFixed(2)} ₽
                        </span>
                      </>
                      :
                      <span style={{fontSize: 20}}>
                          {Number(item.price * item.count).toFixed(2)} ₽
                      </span>
                    :
                    _.find(calculateCheck.body, {goods: item.s_identity}) && Number(_.find(calculateCheck.body, {goods: item.s_identity}).discount) !== 0 ?
                    <>
                      <span className='basic-price'>
                        {Number(item.price * item.count).toFixed(2)} ₽
                      </span>
                      <span className='price-with-discount'>
                      {isLoading ? <Icon
                        type='loading'/> : _.find(calculateCheck.body, {goods: item.s_identity}) && Number((item.price * item.count) - _.find(calculateCheck.body, {goods: item.s_identity}).discount).toFixed(2)} ₽
                      </span>
                    </>
                    :
                    <span style={{fontSize: 20}}>
                        {Number(item.price * item.count).toFixed(2)} ₽
                    </span>
                    }
                    {useApiV2 ? 
                    (_.find(calculateCheck.items, {code: item.s_identity}) && Number(_.find(calculateCheck.items, {code: item.s_identity}).calc_bonus_add) > 0) &&
                    <div>
                      {`Начисление бонусов: `}
                      {isLoading ? <Icon
                        type='loading'/> : Number(_.find(calculateCheck.items, {code: item.s_identity}).calc_bonus_add).toFixed(2)}
                    </div>
                    :
                    (_.find(calculateCheck.body, {goods: item.s_identity}) && Number(_.find(calculateCheck.body, {goods: item.s_identity}).bonus) > 0) &&
                    <div>
                      {`Начисление бонусов: `}
                      {isLoading ? <Icon
                        type='loading'/> : Number(_.find(calculateCheck.body, {goods: item.s_identity}).bonus).toFixed(2)}
                    </div>
                    }
                  </Brief>
                </List.Item>
                {useApiV2 ? 
                 !!_.find(calculateCheck.items, {code: item.s_identity}) && _.find(calculateCheck.items, {code: item.s_identity}).actions &&
                 <Accordion accordion className="action-accordion">
                   <Accordion.Panel header="Действующие акции">
                     <List className="action-list">
                       {_.find(calculateCheck.items, {code: item.s_identity}).actions.map((item,key) =>
                         <Item key={key} extra={item.isUsed ? "Активна" : "Отключена"} align="middle">
                           {item.bonus === 0 ? `Скидка ${Number(item.discount).toFixed(2)} ₽` : `${Number(item.bonus).toFixed(2)} бонусов`} <Brief>{item.name}</Brief>
                         </Item>
                       )}
                     </List>
                   </Accordion.Panel>
                 </Accordion>
                :
                !!_.find(calculateCheck.body, {goods: item.s_identity}) && _.find(calculateCheck.body, {goods: item.s_identity}).actions &&
                  <Accordion accordion className="action-accordion">
                    <Accordion.Panel header="Действующие акции">
                      <List className="action-list">
                        {_.find(calculateCheck.body, {goods: item.s_identity}).actions.map((item,key) =>
                          <Item key={key} extra={item.isUsed ? "Активна" : "Отключена"} align="middle">
                            {item.bonus === 0 ? `Скидка ${Number(item.discount).toFixed(2)} ₽` : `${Number(item.bonus).toFixed(2)} бонусов`} <Brief>{item.name}</Brief>
                          </Item>
                        )}
                      </List>
                    </Accordion.Panel>
                  </Accordion>
                }
              </SwipeAction>
            </QueueAnim>
          ))}
        </List>
      </>
      }

      <WhiteSpace size='xl' />
      {calculateCheck.empty
        ?
        <WingBlank style={{textAlign: 'center'}}>
          <Button onClick={() => sendReturn()}>Оформить возврат</Button>
          <WhiteSpace size='xl' />
          <img src="/img/clientImg.png" alt="Логотип" />
        </WingBlank>
        :
        <WingBlank>
          <Flex direction="column" justify="start" align="start">
            <Flex.Item className="block-text-company">Итого: {isLoading ? <Icon type='loading'/> : useApiV2 ? (calculateCheck.total_check_amount - calculateCheck.total_discount).toFixed(2) : (calculateCheck.total.amount - calculateCheck.total.discount).toFixed(2)} ₽</Flex.Item>
            <WhiteSpace />
            <Flex.Item className="block-text-company">Скидка: {isLoading ? <Icon type='loading'/> : useApiV2 ? Number(calculateCheck.total_discount).toFixed(2) : Number(calculateCheck.total.discount).toFixed(2)} ₽</Flex.Item>
            <WhiteSpace />
            <Flex.Item className="block-text-company">Начисление бонусов: {isLoading ? <Icon type='loading'/> : useApiV2 ? Number(calculateCheck.total_add_bonus).toFixed(2) : Number(calculateCheck.total.bonus).toFixed(2)}</Flex.Item>
          </Flex>
        </WingBlank>
      }

      <Modal
        visible={showModal}
        transparent
        maskClosable={true}
        onClose={() => setShowModal(false)}
        footer={
          [
            { text: 'Отменить', onPress: () => { setShowModal(false)} },
            { text: 'Применить', onPress: () => { confirmNewPrices()} }
          ]
        }
      >
        <Flex direction="column">
          <Flex.Item style={{width: '100%'}}>
            Цена товара
            <InputItem
                {...getFieldProps('price', {
                  rules: [{required: true, message: 'Цена обязательна к заполнению'}],
                  onChange: e => setActiveElem({...activeElem, price: e})
                })}
                value={activeElem.price}
                clear
                type="digit"
                placeholder="Введите цену товара"
                extra="₽"
            />
          </Flex.Item>
          <Flex.Item style={{width: '100%', marginTop: 20}}>
            МРЦ
            <InputItem
                {...getFieldProps('mrc', {
                  rules: [{required: true, message: 'МРЦ обязательна к заполнению'}],
                  onChange: e => setActiveElem({...activeElem, mrc: e})
                })}
                value={activeElem.mrc}
                clear
                type="digit"
                placeholder="Введите МРЦ"
                extra="₽"
            />
          </Flex.Item>
        </Flex>
      </Modal>

      <Modal
        animationType="slide-up"
        popup
        visible={showModalCheck}
        transparent
        onClose={() => setShowModalCheck(false)}
        maskClosable={!isLoading}
      >
        <Flex direction="column" justify="start" alignContent="start" align="start">
          {calculateCheck.empty
            ?
            <Flex.Item style={{width: 200}}>
              Сумма чека
              <InputItem
                {...getFieldProps('amount', {
                  onChange: e => setDefaultAmount(Number(e))
                })}
                error={hasError}
                clear
                value={Number(defaultAmount)}
                type="digit"
                extra="₽"
              />
            </Flex.Item>
            :
            <Flex.Item className='main-card-text'>
              {`Сумма чека ${useApiV2 ? (calculateCheck.total_check_amount - calculateCheck.total_discount).toFixed(2) : (calculateCheck.total.amount - calculateCheck.total.discount).toFixed(2)} ₽`}
            </Flex.Item>
          }
          <WhiteSpace/>
          {calculateCheck.empty
            ?
            <Flex.Item className='main-card-text'>
              Остаток бонусов {clientBalance}
            </Flex.Item>
            :
            <Flex.Item className='main-card-text'>
              Остаток доступных бонусов {clientBalance >= maxBonusLimit ? maxBonusLimit : clientBalance}
            </Flex.Item>
          }
          <WhiteSpace style={{paddingTop: 30}}  />
          <Flex.Item>
            Сумма списываемых бонусов
            <InputItem
              {...getFieldProps('payBonus', {
                onChange: e => setPayBonus(e)
              })}
              error={hasError}
              clear
              value={useApiV2 ? calculateCheck.pay_bonus : calculateCheck.total.paybonus}
              type="digit"
            />
          </Flex.Item>
          <span className="sub-description">Доступно бонусов к списанию: {maxBonusCanPay}</span>
          {getFieldError('payBonus')}
          <WhiteSpace style={{paddingTop: 30}}  />
          <Flex.Item className='main-card-text'>
            Итого к оплате: {isLoading ? <Icon
                        type='loading'/> : <span style={{fontSize: 25, fontWeight: 700}}> {useApiV2 ? ((calculateCheck.total_check_amount - calculateCheck.total_discount) - calculateCheck.pay_bonus) < 0 ? 0 : parseFloat((calculateCheck.total_check_amount - calculateCheck.total_discount) - calculateCheck.pay_bonus).toFixed(2) : ((calculateCheck.total.amount - calculateCheck.total.discount) - calculateCheck.total.paybonus) < 0 ? 0 : parseFloat((calculateCheck.total.amount - calculateCheck.total.discount) - calculateCheck.total.paybonus).toFixed(2)} ₽</span>}
          </Flex.Item>
          <WhiteSpace style={{paddingTop: 80}}  />
          <Flex justify="center" wrap="wrap" style={{width: '100%'}}>
            <Flex.Item>
              <Button
                disabled={isLoading}
                onClick={() => setShowModalCheck(false)}
              >
                Отмена
              </Button>
            </Flex.Item>
            <Flex.Item>
              <Button
                loading={isLoading}
                type="primary"
                disabled={(useApiV2 ? !calculateCheck.total_check_amount : !calculateCheck.total.amount) || hasError || isLoading}
                onClick={() =>
                  (needVerify && (useApiV2 ? Number(calculateCheck.pay_bonus) : Number(calculateCheck.total.paybonus)) !== 0) ? sendCode() : payOrder()}
              >
                Оплатить
              </Button>
            </Flex.Item>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        visible={showModalPay}
        transparent
        maskClosable={true}
        onClose={() => setShowModalPay(false)}
        title="Подтверждение операции"
        footer={
          formValue.pin.length === 5
            ?
            [
              { text: 'Отменить', onPress: () => { setShowModalPay(false)} },
              { text: 'Применить', disabled: true, onPress: () => { validateCode()} }
            ]
            :
            [
              { text: 'Отменить', onPress: () => { setShowModalPay(false)} }
            ]
        }
      >
        <Flex justify="center" wrap="wrap">
          <Flex.Item>
            <RICIBs
              amount={5}
              handleOutputString={e => handleOutput(e)}
              inputRegExp={/^[0-9]$/}
            />
          </Flex.Item>
        </Flex>
      </Modal>

      <Modal
        animationType="slide-up"
        popup
        visible={showModalCoupon}
        transparent
        onClose={() => setShowModalCoupon(false)}
        maskClosable={!isLoading}
      >
        <Flex direction="column" justify="start" alignContent="start" align="start">
          <Flex.Item style={{width: 200}}>
            Номер купона
            <InputItem
              {...getFieldProps('coupon', {
                onChange: e => setCoupon(e)
              })}
              error={hasError}
              clear
              value={coupon}
            />
          </Flex.Item>
          <WhiteSpace style={{paddingTop: 80}}  />
          <Flex justify="center" wrap="wrap" style={{width: '100%'}}>
            <Flex.Item>
              <Button
                disabled={isLoading}
                onClick={() => setShowModalCoupon(false)}
              >
                Отмена
              </Button>
            </Flex.Item>
            <Flex.Item>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                onClick={() => setCheckCoupon()}
              >
                Применить
              </Button>
            </Flex.Item>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        animationType="slide-up"
        popup
        visible={showModalPromocode}
        transparent
        onClose={() => setShowModalPromocode(false)}
        maskClosable={!isLoading}
      >
        <Flex direction="column" justify="start" alignContent="start" align="start">
          <Flex.Item style={{width: 200}}>
            Промокод
            <InputItem
              {...getFieldProps('gift_code', {
                onChange: e => setPromocode(e)
              })}
              error={hasError}
              clear
              value={promocode}
            />
          </Flex.Item>
          <WhiteSpace style={{paddingTop: 80}}  />
          <Flex justify="center" wrap="wrap" style={{width: '100%'}}>
            <Flex.Item>
              <Button
                disabled={isLoading}
                onClick={() => setShowModalPromocode(false)}
              >
                Отмена
              </Button>
            </Flex.Item>
            <Flex.Item>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                onClick={() => setCheckPromocode()}
              >
                Применить
              </Button>
            </Flex.Item>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        animationType="slide-up"
        popup
        visible={showModalCertificate}
        transparent
        onClose={() => setShowModalCertificate(false)}
        maskClosable={!isLoading}
      >
        <Flex direction="column" justify="start" alignContent="start" align="start">
          <Flex.Item style={{width: 200}}>
            Номер сертификата
            <InputItem
              {...getFieldProps('certificate', {
                onChange: e => setCertificate(e)
              })}
              error={hasError}
              clear
              value={certificate}
            />
          </Flex.Item>
          <WhiteSpace style={{paddingTop: 10}}  />
          <Flex.Item style={{width: 200}}>
            Пин-код сертификата
            <InputItem
              {...getFieldProps('certificatePin', {
                onChange: e => setCertificatePin(e)
              })}
              error={hasError}
              clear
              value={certificatePin}
            />
          </Flex.Item>
          <WhiteSpace style={{paddingTop: 80}}  />
          <Flex justify="center" wrap="wrap" style={{width: '100%'}}>
            <Flex.Item>
              <Button
                disabled={isLoading}
                onClick={() => setShowModalCertificate(false)}
              >
                Отмена
              </Button>
            </Flex.Item>
            <Flex.Item>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                onClick={() => setCheckCertificate()}
              >
                Применить
              </Button>
            </Flex.Item>
          </Flex>
        </Flex>
      </Modal>

      <WhiteSpace size='xl' />
      <WingBlank style={{textAlign: 'center'}}>
        {useApiV2 && (useApiV2 ? calculateCheck.items : calculateCheck.body) && !calculateCheck.empty &&
        <Button loading={isLoading} disabled={isLoading} type="primary" onClick={() => {
          setPayBonus((clientBalance >= maxBonusLimit || (calculateCheck.body && calculateCheck.empty)) ? maxBonusLimit : clientBalance)
          !isLoading && setShowModalCoupon(true)
        }}>
          Применить купон
        </Button>
        }
      </WingBlank>
      <WhiteSpace size='sm' />
      <WingBlank style={{textAlign: 'center'}}>
        {useApiV2 && (useApiV2 ? calculateCheck.items : calculateCheck.body) && !calculateCheck.empty &&
        <Button loading={isLoading} disabled={isLoading} type="primary" onClick={() => {
          setPayBonus((clientBalance >= maxBonusLimit || (calculateCheck.body && calculateCheck.empty)) ? maxBonusLimit : clientBalance)
          !isLoading && setShowModalCertificate(true)
        }}>
          Применить сертификат
        </Button>
        }
      </WingBlank>
      <WhiteSpace size='sm' />
      <WingBlank style={{textAlign: 'center'}}>
        {useApiV2 && (useApiV2 ? calculateCheck.items : calculateCheck.body) && !calculateCheck.empty &&
        <Button loading={isLoading} disabled={isLoading} type="primary" onClick={() => {
          setPayBonus((clientBalance >= maxBonusLimit || (calculateCheck.body && calculateCheck.empty)) ? maxBonusLimit : clientBalance)
          !isLoading && setShowModalPromocode(true)
        }}>
          Применить промокод
        </Button>
        }
      </WingBlank>
      <WhiteSpace size='sm' />
      {(useApiV2 ? calculateCheck.items : calculateCheck.body) &&
      <Button loading={isLoading} disabled={isLoading} type="primary" className="pay-button" onClick={() => {
        setPayBonus((clientBalance >= maxBonusLimit || (calculateCheck.body && calculateCheck.empty)) ? maxBonusLimit : clientBalance)
        !isLoading && setShowModalCheck(true)
      }}>
        Перейти к оплате
      </Button>
      }
    </>
  )
});

export default createForm()(ClientCheckScreen)
